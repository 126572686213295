<template>
    <div id="footer">
        <b-container>
        <b-row class="align-items-center">
            <b-col cols="12" md="4">
                <div class="h5">UO Konsult AB</div>
                <div class="h6">556213-4477</div>
            </b-col>
             <b-col cols="12" md="4">
                 <div>Bråland 330</div>
                 <div>444 92, Jörlanda</div>
            </b-col>
             <b-col cols="12" md="4">
                 <div>kontakt@uokonsult.se</div>
                 <div class="align-self-end" style="font-size:small">Copyright UO Konsult AB {{year}}</div>
            </b-col>
        </b-row>
        </b-container>
    </div>
</template>


<script>

export default {
    name: "Footer",    
    computed: {
       year(){
           var d = new Date();
           return d.getFullYear();
       }
    },
};
</script>
